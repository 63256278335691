@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat-Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: local('Montserrat-ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3g3D1pZl0ti.woff2) format('woff2');
    font-display: swap;
}

/* You can add global styles to this file, and also import other style files */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    overflow-x: hidden;
}

.center-align {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}

.cs-bg-button {
    background-color: #119fdb;
    color: #ffff;
    padding: 6px 12px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    outline: none;
    border: 1px solid #119fdb;
}

.cs-bg-button:hover {
    background-color: #ffff;
    color: #119fdb;
    border: 1px solid #119fdb;
}


.cs-bg-secondary {
    background-color: #F7F8F8;
}

.cs-color-secondary {
    color: #F7F8F8;
}

.cs-color-primary {
    color: #119fdb
}

.cs-bg-primary {
    background-color: #119fdb;
}

/* Bootstrap reverse flow  */

/* Style the "Edit Bundle" button
border-radius :7px;
padding : 5px 10px */
.mat-card:not([class*=mat-elevation-z]) {
    box-shadow: none;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 10px 10px !important;
    line-height: 0px;
}